<template>
  <div>
    <div
        class="row mt-3"
        :class="$store.getters['layout/getInnerWidth'] < 1532 ? '' : 'justify-content-end mr-2'"
    >
      <div
          align="right"
          class="mt-1"
          :class="$store.getters['layout/getInnerWidth'] < 1532 ? 'col-6' : 'col-4'"
      >
        <div :style="cssVars" class="default-line-legend"></div>
      </div>
      <div class="text-legend-line">
        {{ $t(legend) }}
      </div>
    </div>
    <chartist
        :style="cssVars"
        class="ct-5 flot-chart-container"
        ratio="ct-major-second"
        :type="type"
        :data="chart5.data"
        :options="chart5.options">
    </chartist>
  </div>
</template>
<script>
  export default {
    props: {
      legend: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: () => 'Bar'
      },
      datasets: {
        type: Array,
        default: () => [],
      },
      chartColor: {
        type: String,
        default: '#000'
      }
    },
    computed: {
      cssVars() {
        return {
          '--chart-line-color' : this.chartColor
        }
      }
    },
    data: () => ({
      chart5 : {
        data: {
          labels: [],
          series: []
        },
        options: {
          low: 0,
              showArea: true,
              showPoint: true,
              axisX: {
                showLabel: true,
                showGrid: false
          },
          axisY: {
            labelInterpolationFnc: function(value){
              return parseInt(value) ;
            }
            },
          fullWidth: true,
              responsive: true,
        },
      },
    }),
    created ()  {
      this.chart5.data.labels =  this.datasets[0];
      this.chart5.data.series.push(this.datasets[1]);
    }
  }
</script>
<style lang="scss" scoped>
/deep/ .ct-series .ct-line {
  stroke: var(--chart-line-color);
}
/deep/ .ct-point {
  stroke: var(--chart-line-color);
}

.default-line-legend {
  background: var(--chart-line-color);
  border-radius: 6px;
  opacity: 1;
  width: 60px;
  letter-spacing: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 65px;
  padding-right: 20px;
}
</style>
